import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

const SuggestionList = ({ data = [] }) => {
  const list = data.map(ad => (
    <li key={ad.id} className="suggestion">
      <Link href={`/items/${ad.id}`} className="suggestion__link">
        {ad.id}
      </Link>
    </li>
  ));

  return <ul className="suggestion-list">{list}</ul>;
};

SuggestionList.propTypes = {
  data: PropTypes.array
};

export default SuggestionList;
